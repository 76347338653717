<template>
    <!--内容-->
    <div class="page-content-x">
        <!--搜索-->
        <div class="page-content-search">
            <el-form ref="form" :model="search" size="mini" :label-width="this.env.search_label_width">
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="昵称">
                            <el-input v-model="search.user_nick"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="手机号">
                            <el-input v-model="search.phone"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item>
                            <el-button icon="el-icon-search" size="mini" type="primary" @click="is_search">搜索</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div style="height: 20px;"></div>
        <!--列表-->
        <el-table
                v-loading="loading"
                :data="tableData"
                border
                size="medium">
            <el-table-column
                    prop="user_uuid"
                    label="用户uuid">
            </el-table-column>
            <el-table-column
                    prop="avatar_url"
                    label="头像"
                    width="80">
                <template slot-scope="scope">
                    <el-avatar shape="square" :size="22" :src="scope.row.avatar_url"></el-avatar>
                </template>
            </el-table-column>
            <el-table-column
                    prop="user_nick"
                    label="昵称"
                    min-width="150">
            </el-table-column>
            <el-table-column
                    prop="phone"
                    label="手机号"
                    width="200">
            </el-table-column>
            <el-table-column
                    prop="gender"
                    label="性别"
                    width="160">
                <template slot-scope="scope">
                    <el-tag size="mini" v-if="scope.row.gender===0" type="info">未知</el-tag>
                    <el-tag size="mini" v-if="scope.row.gender===1">男</el-tag>
                    <el-tag size="mini" v-if="scope.row.gender===2" type="danger">女</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="create_time"
                    label="关注时间"
                    width="150">
            </el-table-column>
            <el-table-column
                    prop="update_time"
                    label="更新时间"
                    width="150">
            </el-table-column>
            <el-table-column
                    prop="from_type"
                    label="来源"
                    width="150">
                <template slot-scope="scope">
                    <el-tag size="mini" v-if="scope.row.from_type==='qrcode'">二维码</el-tag>
                    <el-tag size="mini" v-else type="info">-</el-tag>
                </template>
            </el-table-column>
        </el-table>
        <div style="height: 20px;"></div>
        <!--分页-->
        <el-pagination
                @current-change="getlist"
                :page-size="this.env.pageSize"
                :pager-count="7"
                background
                layout="prev, pager, next, total"
                :current-page.sync="page"
                :total="count">
        </el-pagination>

    </div>
</template>
<script>
export default {
    props: {
        referrer_uuid: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            page_name: '拉新列表',
            loading: true,      // 加载状态
            searchtime: null,     //时间
            search: {},         // 搜索内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [],// 列表内容
        }
    },
    // 创建
    created() {
    },
    // 安装
    mounted() {
    },
    methods: {
        // 判断是否为空
        is_empty(auth) {
            return this.Tool.is_empty(auth)
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init(referrer_uuid,searchtime) {
            this.tableData = []
            this.search = {
                referrer_uuid,
            }
            this.searchtime = searchtime
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "user.member.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            // 判断时间条件
            if (this.searchtime !== null) {
                postdata.starttime = this.searchtime[0]
                postdata.endtime = this.searchtime[1]
            }
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*表格不换行*/
>>> .el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.form-tag_colour-div {
    min-height: 200px;
}

</style>
